import {ILanguage, MICRO_INCENTIVE_TYPE, OTHER_CONTAINER_TYPE, TREE_STAGE} from './constants';

export enum NOTIFICATION_TYPE {
  TEST = 'TEST',
  MGM_BONUS = 'MGM_BONUS',
  MGM_REMEMBER_THROW = 'MGM_REMEMBER_TRHOW',
  LOTTERY_PRIZE_WIN = 'LOTTERY_PRIZE_WIN',
  CONTACT_MESSAGE = 'CONTACT_MESSAGE',
  GENERAL_COMMUNICATION = 'GENERAL_COMMUNICATION',
  SMART_BIN_THROW_PROCESSED = 'SMART_BIN_THROW_PROCESSED',
  ABOUT_TO_EXPIRE_CLAIM_PRIZE = 'ABOUT_TO_EXPIRE_CLAIM_PRIZE',
  ABOUT_TO_EXPIRE_COLLECT_PRIZE = 'ABOUT_TO_EXPIRE_COLLECT_PRIZE',
  MICROINCENTIVE_GENERATE_PLAY = 'MICROINCENTIVE_GENERATE_PLAY',
  DIRECT_PRIZE_WIN = 'DIRECT_PRIZE_WIN',
  WELCOMED_USERS_REACTIVATION = 'WELCOMED_USERS_REACTIVATION',
  MICROINCENTIVE_DIRECT_FAILED = 'MICROINCENTIVE_DIRECT_FAILED',
  RETREE = 'RETREE',
  RETREE_FLUJO_NO_INICIADO_1 = 'RETREE_FLUJO_NO_INICIADO_1',
  RETREE_FLUJO_NO_INICIADO_2 = 'RETREE_FLUJO_NO_INICIADO_2',
  RETREE_FLUJO_NO_INICIADO_3 = 'RETREE_FLUJO_NO_INICIADO_3',
  RETREE_FLUJO_INICIADO_1 = 'RETREE_FLUJO_INICIADO_1',
  RETREE_FLUJO_INICIADO_2 = 'RETREE_FLUJO_INICIADO_2',
  RETREE_FLUJO_INICIADO_3 = 'RETREE_FLUJO_INICIADO_3',
  RETREE_FLUJO_INICIADO_4 = 'RETREE_FLUJO_INICIADO_4',
  PILOT_EXPIRED = 'PILOT_EXPIRED',
  INVALID_CONTAINER = 'INVALID_CONTAINER',
  VALID_CONTAINER = 'VALID_CONTAINER',
  BAD_PHOTO_TAKEN_CONTAINER = 'BAD_PHOTO_TAKEN_CONTAINER ',
  FRAUD_CONTAINER = 'FRAUD_CONTAINER',
  OTHER_CONTAINER = 'OTHER_CONTAINER',
}

export enum NOTIFICATION_TEXT_TYPE {
  REACTIVATION_DAY_2 = 'REACTIVATION_DAY_2',
  REACTIVATION_DAY_5 = 'REACTIVATION_DAY_5',
  OLD_USERS_REACTIVATION_DAY_2 = 'OLD_USERS_REACTIVATION_DAY_2',
  OLD_USERS_REACTIVATION_DAY_5 = 'OLD_USERS_REACTIVATION_DAY_5',
  COMPENSATE_ACTIVE_USERS_DAY_0 = 'COMPENSATE_ACTIVE_USERS_DAY_0',
  COMPENSATE_ACTIVE_USERS_DAY_5 = 'COMPENSATE_ACTIVE_USERS_DAY_5',
  POLL_REWARD = 'POLL_REWARD',
}

export type NotificationsTypes =
  | {
      type: NOTIFICATION_TYPE.TEST;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.PILOT_EXPIRED;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.MICROINCENTIVE_GENERATE_PLAY;
      params: {
        microIncentiveId: string;
        type: MICRO_INCENTIVE_TYPE;
        prize: ILanguage;
        limitDate: string;
        prizeItem: ILanguage;
      };
    }
  | {
      type: NOTIFICATION_TYPE.DIRECT_PRIZE_WIN;
      params: {
        directPrizeId: string;
        prize: ILanguage;
        limitDate?: string;
        prizeItem?: ILanguage;
      };
    }
  | {
      type: NOTIFICATION_TYPE.MGM_BONUS;
      params: {
        reciclos: number;
      };
    }
  | {
      type: NOTIFICATION_TYPE.MGM_REMEMBER_THROW;
      params: {
        reciclos: number;
      };
    }
  | {
      type: NOTIFICATION_TYPE.LOTTERY_PRIZE_WIN;
      params: {
        prizeId: string;
        title: ILanguage;
        prize: ILanguage;
        lotteryId: string;
        url: string;
      };
    }
  | {
      type: NOTIFICATION_TYPE.GENERAL_COMMUNICATION;
      params?: {
        text?: ILanguage;
        reciclos?: number;
      };
    }
  | {
      type: NOTIFICATION_TYPE.SMART_BIN_THROW_PROCESSED;
      params: {
        reciclos: number;
      };
    }
  | {
      type: NOTIFICATION_TYPE.ABOUT_TO_EXPIRE_CLAIM_PRIZE;
      params: {
        numberOfDays: number;
        prizeId: string;
      };
    }
  | {
      type: NOTIFICATION_TYPE.ABOUT_TO_EXPIRE_COLLECT_PRIZE;
      params: {
        numberOfDays: number;
        prizeId: string;
      };
    }
  | {
      type: NOTIFICATION_TYPE.CONTACT_MESSAGE;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.WELCOMED_USERS_REACTIVATION;
      params: {
        username: string;
      };
    }
  | {
      type: NOTIFICATION_TYPE.MICROINCENTIVE_DIRECT_FAILED;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.RETREE_FLUJO_NO_INICIADO_1;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.RETREE_FLUJO_NO_INICIADO_2;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.RETREE_FLUJO_NO_INICIADO_3;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.RETREE_FLUJO_INICIADO_1;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.RETREE_FLUJO_INICIADO_2;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.RETREE_FLUJO_INICIADO_3;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.RETREE_FLUJO_INICIADO_4;
      params: undefined;
    }
  | {
      type: NOTIFICATION_TYPE.BAD_PHOTO_TAKEN_CONTAINER;
      params: {
        reciclos: number;
        municipio: string;
      };
    }
  | {
      type: NOTIFICATION_TYPE.FRAUD_CONTAINER;
      params: {
        municipio: string;
      };
    }
  | {
      type: NOTIFICATION_TYPE.VALID_CONTAINER;
      params: {
        reciclos: number;
        municipio: string;
      };
    }
  | {
      type: NOTIFICATION_TYPE.INVALID_CONTAINER;
      params: {
        municipio: string;
      };
    }
  | {
      type: NOTIFICATION_TYPE.OTHER_CONTAINER;
      params: {
        municipio: string;
        otherContainer: OTHER_CONTAINER_TYPE;
      };
    }
  | {
      type: NOTIFICATION_TYPE.RETREE;
      params: {
        stage: TREE_STAGE;
      };
    };
