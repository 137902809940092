import {USER_ACTIONS} from './actions';
import {ServerEndpointsByActionsType} from './types';

export const PermissionEndpoints: ServerEndpointsByActionsType = {
  /**
   * No Auth
   */
  [USER_ACTIONS.NO_AUTH]: [
    'v1.auth.logic.status',
    'v1.transactions.merkle.tree.logic.validate',
    'v1.auth.logic.islandMode',
    'v1.packaging.merkle.tree.logic.validate',
    'v1.packaging.merkle.tree.logic.verify',
  ],
  [USER_ACTIONS.USE_PUBLIC_BACKOFFICE]: [
    'v1.auth.logic.login',
    'v1.auth.logic.loginEmail',
    'v1.auth.logic.token',
    'v1.auth.logic.check',
    'v1.statistics.backoffice.logic.heatmap',
    'v1.statistics.backoffice.logic.reciclosSpent',
  ],
  [USER_ACTIONS.USE_GUEST_WEBAPP]: [
    'v1.auth.logic.login',
    'v1.auth.logic.loginEmail',
    'v1.auth.logic.token',
    'v1.auth.logic.loginGoogle',
    'v1.auth.logic.loginApple',
    'v1.auth.logic.loginFacebook',
    'v1.auth.logic.check',
    'v1.auth.logic.isValid',
    'v1.auth.logic.register',
    'v1.auth.logic.registerUserContainer',
    'v1.auth.logic.validate',
    'v1.auth.logic.forgotPassword',
    'v1.auth.logic.resetPassword',
    'v1.auth.logic.resendVerificationSms',
    'v1.communities.logic.welcomeValue',
    'v1.communities.logic.hasSmartBins',
    'v1.communities.logic.isAvant',
    'v1.mgm.logic.infoInvited',
    'v1.prizes.logic.checkCode',
    'v1.prizes.logic.checkCollectionPoint',
    'v1.prizes.logic.uploadPhoto',
    'v1.prizes.logic.collect',
    'v1.posts.users.logic.getPostUserInfoById',
    'v1.posts.users.logic.markAsPublic',
    'v1.legal.texts.logic.getActiveLegalText',
    'v1.legal.texts.logic.hasAcceptedLegalTextOnChangeLocation',
    'v1.feature.flag.logic.getClientGenericConfig',
  ],
  [USER_ACTIONS.USE_GUEST_AIRE]: [
    'v1.auth.logic.loginAire',
    'v1.auth.logic.token',
    'v1.auth.logic.loginAireGoogle',
    'v1.auth.logic.loginAireFacebook',
    'v1.auth.logic.check',
    'v1.auth.logic.isValid',
    'v1.auth.logic.registerUserAire',
    'v1.auth.logic.validateAire',
    'v1.auth.logic.forgotPasswordAire',
    'v1.auth.logic.resetPasswordAire',
    'v1.auth.logic.resendVerificationSmsAire',
  ],
  /**
   * Authorized
   */
  [USER_ACTIONS.USE_AUTHORIZED_WEBAPP]: [
    'v1.auth.logic.me',
    'v1.user.actions.logic.save',
    'v1.retree.logic.info',
    'v1.retree.logic.current',
    'v1.retree.logic.list',
    'v1.retree.logic.progress',
    'v1.retree.logic.setName',
    'v1.lotteries.logic.list',
    'v1.lotteries.logic.participated',
    'v1.lotteries.logic.buyNumbers',
    'v1.lotteries.logic.get',
    'v1.lotteries.logic.getHomeFeatured',
    'v1.prizes.logic.list',
    'v1.prizes.logic.claim',
    'v1.prizes.logic.status',
    'v1.transactions.logic.list',
    'v1.barcode.logic.check',
    'v1.direct.prize.logic.list',
    'v1.direct.prize.logic.get',
    'v1.direct.prize.logic.participated',
    'v1.crowd.logic.list',
    'v1.crowd.logic.participated',
    'v1.crowd.logic.get',
    'v1.crowd.logic.donate',
    'v1.crowd.logic.finished',
    'v1.notifications.logic.list',
    'v1.notifications.logic.count',
    'v1.bucket.logic.photosByStatus',
    'v1.bucket.logic.allPhotosByStatus',
    'v1.bucket.logic.info',
    'v1.bucket.logic.clear',
    'v1.bucket.logic.clearDoorToDoor',
    'v1.bucket.logic.clearAvant',
    'v1.bucket.logic.noQr',
    'v1.recycle.photo.logic.remove',
    'v1.recycle.photo.logic.details',
    'v1.recycle.photo.logic.revision',
    'v1.communities.logic.getRecyclingMechanics',
    'v1.communities.logic.getChangeCommunityInfo',
    'v1.container.logic.nearest',
    'v1.container.logic.box',
    'v1.container.logic.scoringResult',
    'v1.mgm.logic.info',
    'v1.mgm.logic.mgmUserInfo',
    'v1.mgm.logic.referredUsersList',
    'v1.mgm.logic.hasReferredUserRecycled',
    'v1.contact.logic.send',
    'v1.contact.logic.list',
    'v1.modal.logic.activeModals',
    'v1.statistic.logic.mgm',
    'v1.users.logic.edit',
    'v1.users.logic.setEmail',
    'v1.users.logic.friends',
    'v1.users.logic.language',
    'v1.users.logic.closeAccount',
    'v1.users.logic.deleteAccount',
    'v1.users.logic.answerRecycle',
    'v1.users.logic.checkIncentives',
    'v1.users.logic.accept',
    'v1.users.logic.registerPushToken',
    'v1.users.logic.hasGoogleAccount',
    'v1.users.logic.upload',
    'v1.users.logic.remove',
    'v1.users.logic.setSmartRingCard',
    'v1.users.logic.isInAvantCommunity',
    'v1.users.logic.participated',
    'v1.smart.bin.logic.throw',
    'v1.recycle.photo.logic.upload',
    'v1.recycle.photo.logic.list',
    'v1.micro.incentive.play.logic.list',
    'v1.micro.incentive.play.logic.get',
    'v1.micro.incentive.play.logic.play',
    'v1.micro.incentive.logic.generatePlay',
    'v1.micro.incentive.logic.list',
    'v1.micro.incentive.logic.participated',
    'v1.micro.incentive.logic.get',
    'v1.micro.incentive.logic.enabledScannerQueueIt',
    'v1.direct.incentive.logic.get',
    'v1.direct.incentive.logic.list',
    'v1.direct.incentive.logic.participated',
    'v1.direct.incentive.logic.buy',
    'v1.home.logic.metrics',
    'v1.posts.users.logic.list',
    'v1.posts.users.logic.getPostUserInfoById',
    'v1.posts.users.logic.read',
    'v1.posts.users.logic.discardAndNext',
    'v1.posts.users.logic.view',
    'v1.posts.users.logic.markAsPublic',
    'v1.posts.users.logic.discarded',
    'v1.promotion.logic.participate',
    'v1.promotion.logic.activePromotions',
    'v1.promotion.logic.freeRetreeReciclos',
    'v1.users.logic.updateNotificationPermissions',
    'v1.communication.logic.updatePermission',
    'v1.communication.logic.updateMultiPermission',
    'v1.communication.logic.getPermissions',
    'v1.smart.ring.logic.scanQr',
    'v1.smart.ring.logic.bleThrow',
    'v1.smart.ring.logic.bleId',
    'v1.promotional.code.logic.exchangePromotionalCode',
    'v1.feature.flag.logic.checkFeatureFlags',
    'v1.promotional.code.logic.listUserExchangedCodes',
    'v1.legal.texts.logic.userUpToDateTexts',
  ],
  [USER_ACTIONS.VIVA_MARKETING]: [
    // For alias, dont change current viva marketing url
    'v1.lotteries.logic.create',
    'v1.lotteries.logic.edit',
    'v1.lotteries.logic.get',
    'v1.lotteries.logic.list',
    'v1.crowd.logic.create',
    'v1.crowd.logic.edit',
    'v1.crowd.logic.get',
    'v1.crowd.logic.list',
    'v1.communities.logic.list',
    // End alias
    'v1.communities.backoffice.logic.list',
    'v1.lotteries.backoffice.logic.create',
    'v1.lotteries.backoffice.logic.edit',
    'v1.lotteries.backoffice.logic.get',
    'v1.lotteries.backoffice.logic.list',
    'v1.crowds.backoffice.logic.create',
    'v1.crowds.backoffice.logic.edit',
    'v1.crowds.backoffice.logic.get',
    'v1.crowds.backoffice.logic.list',
    'v1.micro.incentive.backoffice.logic.create',
    'v1.micro.incentive.backoffice.logic.edit',
    'v1.micro.incentive.backoffice.logic.get',
    'v1.micro.incentive.backoffice.logic.list',
    'v1.reciclos.token.logic.history',
    'v1.direct.incentive.backoffice.logic.create',
    'v1.direct.incentive.backoffice.logic.edit',
    'v1.direct.incentive.backoffice.logic.get',
    'v1.direct.incentive.backoffice.logic.list',
    'v1.direct.prize.backoffice.logic.create',
    'v1.direct.prize.backoffice.logic.edit',
    'v1.direct.prize.backoffice.logic.get',
    'v1.direct.prize.backoffice.logic.list',
  ],
  [USER_ACTIONS.SELF_ADMINISTRATE]: ['v1.users.logic.language', 'v1.communities.backoffice.logic.list'],
  [USER_ACTIONS.MONTHLY_PDF_REPORTS]: ['v1.reports.backoffice.logic.list', 'v1.statistics.backoffice.logic.metric', 'v1.statistics.backoffice.logic.activeCrowds'],
  [USER_ACTIONS.VIEW_DASHBOARD_GLA]: ['v1.communities.backoffice.logic.myCommunities', 'v1.statistics.backoffice.logic.metric', 'v1.statistics.backoffice.logic.listCrowds'],
  [USER_ACTIONS.VIEW_DASHBOARD_KPMG]: [
    'v1.communities.backoffice.logic.myCommunities',
    'v1.statistics.generation.logic.getGroupMetricsSettings',
    'v1.statistics.backoffice.logic.metric',
    'v1.statistics.backoffice.logic.groupCommunities',
  ],
  [USER_ACTIONS.VIEW_STATISTICS_KPMG]: [
    'v1.communities.backoffice.logic.myCommunities',
    'v1.statistics.generation.logic.getGroupMetricsSettings',
    'v1.statistics.backoffice.logic.metric',
    'v1.statistics.backoffice.logic.groupCommunities',
  ],
  [USER_ACTIONS.VIEW_DASHBOARD_MKT]: ['v1.communities.backoffice.logic.myCommunities', 'v1.statistics.backoffice.logic.metric', 'v1.statistics.backoffice.logic.listCrowds'],
  [USER_ACTIONS.CRM]: ['v1.users.logic.unsubscribe'],
  [USER_ACTIONS.DEVELOPERS_MODE]: ['v1.statistics.backoffice.logic.metric'],
  [USER_ACTIONS.UPLOAD_FILES]: ['v1.azure.storage.create', 'v1.smart.bin.logic.uploadInfo'],
  [USER_ACTIONS.DEMO_SMART_RING]: ['v1.smart.ring.backoffice.logic.mint'],
  [USER_ACTIONS.EXECUTE_ACTIONS]: ['v1.actions.logic.execute', 'v1.processes.logic.stop', 'v1.actions.logic.cron'],
  [USER_ACTIONS.REVISE_INCORRECT_PHOTOS]: ['v1.revision.backoffice.logic.validateAndGetClaim'],
  [USER_ACTIONS.REVISE_DUPLICATE_PHOTOS]: ['v1.revision.backoffice.logic.validateAndGetDuplicate'],
  [USER_ACTIONS.CHECK_DOOR_TO_DOOR_PHOTOS]: ['v1.revision.backoffice.logic.checkDoorToDoorAndNext'],
  [USER_ACTIONS.CHECK_AVANT_PHOTOS]: [
    'v1.revision.backoffice.logic.checkAvantPhotoAndNext',
    'v1.revision.backoffice.logic.updateAvantPhotosChecked',
    'v1.revision.backoffice.logic.getAvantPhotos',
  ],
  [USER_ACTIONS.CHECK_MICRO_INCENTIVE_PHOTOS]: [
    'v1.revision.backoffice.logic.addBackupUsers',
    'v1.revision.backoffice.logic.validateAndGetDirectPrizeWinner',
    'v1.micro.incentive.backoffice.logic.listMicroIncentives',
    'v1.micro.incentive.backoffice.logic.getMicroIncentive',
    'v1.direct.prize.backoffice.logic.directPrizesCsv',
    'v1.direct.prize.backoffice.logic.get',
    'v1.direct.prize.backoffice.logic.list',
  ],
  [USER_ACTIONS.USE_EXTERNAL_WEBSERVICE]: ['v1.communities.backoffice.logic.communitiesReciclos'],

  [USER_ACTIONS.SUPERUSER_MARKETING]: [
    'v1.communities.backoffice.logic.communities',
    'v1.communities.backoffice.logic.incentiveList',
    'v1.users.backoffice.logic.findOne',
    'v1.lotteries.backoffice.logic.listLotteriesPerUser',
    'v1.user.actions.backoffice.logic.listNPS',
    'v1.promotional.code.backoffice.list',
    'v1.promotional.code.backoffice.create',
    'v1.promotional.code.backoffice.statusUpdate',
    'v1.promotional.code.backoffice.edit',
    'v1.promotional.code.backoffice.listCommunities',
    'v1.promotional.code.messages.backoffice.list',
    'v1.promotional.code.messages.list',
    'v1.promotional.code.backoffice.getGenericMessages',
    'v1.promotional.code.backoffice.updateGenericMessages',
    'v1.promotional.code.backoffice.sendCrmNotification',
    'v1.promotional.code.backoffice.getExchangesPromCode',
    'v1.promotional.code.backoffice.promotionalCodesCsv',
    'v1.communities.backoffice.logic.donationToCrowd',
    'v1.communities.backoffice.logic.getReciclosDonations',
    'v1.crowds.backoffice.logic.getActive',
    'v1.crowds.backoffice.logic.getDonations',
  ],
  [USER_ACTIONS.TRACKER_CONTAINERS]: [
    'v1.communities.backoffice.logic.listAll',
    //Containers
    'v1.containers.backoffice.logic.list',
    'v1.containers.backoffice.logic.create',
    'v1.containers.backoffice.logic.insertFromFile',
    'v1.containers.backoffice.logic.resolveIncidence',
    'v1.container.locations.backoffice.logic.create',

    //OT
    'v1.ots.backoffice.logic.list',
    'v1.ots.backoffice.logic.create',
    'v1.ots.backoffice.logic.update',
    'v1.ots.backoffice.logic.active',
    'v1.ots.backoffice.logic.findOne',
    //Users
    'v1.users.backoffice.logic.list',
    //Combos
    'v1.combos.backoffice.logic.find',
    'v1.combos.backoffice.logic.findOne',
  ],
  [USER_ACTIONS.USERS_MANAGEMENT]: ['v1.users.backoffice.logic.list', 'v1.users.backoffice.logic.update'],
  [USER_ACTIONS.COORDINADOR_MNG]: [
    'v1.containers.containers.logic.list',
    'v1.containers.containers.logic.activate',

    'v1.location.containers.logic.update',
    'v1.communities.backoffice.logic.listAll',
    'v1.containers.photos.logic.upload',
    'v1.containers.ots.logic.list',
    'v1.containers.ots.logic.findOne',
    'v1.containers.ots.logic.listContainers',
    'v1.containers.ots.logic.assignUserScan',
    'v1.containers.ots.logic.nextStatus',
    'v1.containers.ots.logic.edit',

    'v1.containers.users.logic.findTechnicians',
    'v1.containers.users.logic.findCoordinatorCommunities',
    //Combos
    'v1.combos.backoffice.logic.find',
    'v1.combos.backoffice.logic.findOne',
  ],
  [USER_ACTIONS.CONTAINERS_USER_SCAN]: [
    'v1.containers.containers.logic.list',
    'v1.containers.containers.logic.activate',
    'v1.containers.containers.logic.containerScanExist',

    'v1.location.containers.logic.update',
    'v1.communities.backoffice.logic.listAll',
    'v1.containers.photos.logic.upload',

    //Combos
    'v1.combos.backoffice.logic.find',
    'v1.combos.backoffice.logic.findOne',
  ],
  [USER_ACTIONS.SMARTRING_CRUDL]: [
    'v1.smart.ring.backoffice.logic.create',
    'v1.smart.ring.backoffice.logic.edit',
    'v1.smart.ring.backoffice.logic.removeSrContainer',
    // 'v1.smart.ring.backoffice.logic.get',
    'v1.smart.ring.backoffice.logic.list',
    'v1.smart.ring.backoffice.logic.activate',
    'v1.users.backoffice.logic.listSmartRingTestUsers',
    'v1.users.backoffice.logic.createSmartRingTestUser',
    'v1.users.backoffice.logic.deleteSmartRingTestUser',
    'v1.container.backoffice.logic.listContainers',
    'v1.container.backoffice.logic.smartRingEnableContainer',
    'v1.container.backoffice.logic.smartRingEdit',
    'v1.container.backoffice.logic.smartRingDisableContainer',
  ],
  [USER_ACTIONS.CONTROL_OPERACIONES]: [
    'v1.communities.backoffice.logic.communities',
    'v1.communities.backoffice.logic.incentiveList',

    'v1.users.backoffice.logic.findOne',

    'v1.lotteries.backoffice.logic.listLotteriesPerUser',

    'v1.control.panel.backoffice.logic.allBucketPhotosByStatus',
    'v1.control.panel.backoffice.logic.spentByUser',
    'v1.control.panel.backoffice.logic.spentCrowdsByUser',
    'v1.control.panel.backoffice.logic.spentLotteriesByUser',
    'v1.control.panel.backoffice.logic.spentDirectIncentivesByUser',
    'v1.control.panel.backoffice.logic.spentDirectPrizesByUser',
    'v1.control.panel.backoffice.logic.getUserTransactions',
    'v1.control.panel.backoffice.logic.getUserReceivedTransactions',
    'v1.control.panel.backoffice.logic.getUserSpentTransactions',
    'v1.control.panel.backoffice.logic.getTransactionsRecyclePhoto',
    'v1.users.backoffice.logic.resetPasswordUserApp',
    'v1.users.backoffice.logic.closeAccount',
    'v1.users.backoffice.logic.deleteAccount',
    'v1.users.backoffice.logic.banAccount',
    'v1.users.backoffice.logic.getBansList',
    'v1.users.backoffice.logic.updateUserAppProfile',
    'v1.transactions.backoffice.logic.backofficeTransactionsList',
    'v1.transactions.backoffice.logic.giveReciclos',
  ],
  [USER_ACTIONS.CHECK_HUPI_PHOTOS]: ['v1.revision.backoffice.logic.checkHupiPhotoAndNext'],
  [USER_ACTIONS.USE_AUTHORIZED_AIRE]: [
    'v1.auth.logic.me',
    'v1.users.logic.editUserAire',
    'v1.users.logic.setEmail',
    'v1.users.logic.language',
    'v1.users.logic.deleteAccountAire',
    'v1.users.logic.closeAccountAire',
    'v1.users.logic.registerPushToken',
    'v1.users.logic.hasGoogleAccount',
  ],
  [USER_ACTIONS.GESTION_ENVASES]: [
    'v1.barcode.backoffice.logic.searchBarcode',
    'v1.barcode.backoffice.logic.insertBarcode',
    'v1.barcode.backoffice.logic.editBarcode',
    'v1.recycle.photo.backoffice.logic.getUnprocessedRecyclePhotoByUser',
    'v1.recycle.photo.backoffice.logic.setStatus',
    'v1.bucket.throw.backoffice.logic.getByUser',
    'v1.bucket.throw.backoffice.logic.getBucketThrowDetail',
    'v1.smart.bin.throw.backoffice.logic.getByUser',
    'v1.smart.bin.throw.backoffice.logic.getSmartBinThrowDetail',
    'v1.recycled.packaging.backoffice.logic.getRecyledPackagingByUser',
    'v1.user.containers.backoffice.logic.getContainers',
    'v1.user.containers.backoffice.logic.getUserLocationInfo',
    'v1.user.containers.backoffice.logic.getContainerInfo',
    'v1.user.containers.backoffice.logic.getCommunityInfo',
  ],
};
