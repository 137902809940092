import {COMMUNITY_REGION_TYPE, COMMUNITY_TYPE, DAYS_OF_WEEK, SMART_RING_TYPE, STATISTIC_TYPE} from '../constants';
import {IBase} from './ibase';

export const COMMUNITY_SIMPLE_FIELDS = (<T extends string[]>(...fields: T) => fields)('_id', 'name', 'users', 'featured');

export type ICommunitySimple = Pick<ICommunity, typeof COMMUNITY_SIMPLE_FIELDS[number]>;

export type ICommunity<P extends string = '_'> = IBase & {
  type: COMMUNITY_TYPE;
  secondMechanicType: COMMUNITY_TYPE;
  code: string;
  name: string;
  url: string[];
  featured: boolean;
  featuredChildren: boolean;

  inePostalCode: string;
  ecoembesCode: string;

  regionType: COMMUNITY_REGION_TYPE;
  regionId: string;

  geographicCommunity: P extends 'geographicCommunity' ? ICommunity : string;
  company: string;

  parent: P extends 'parent' ? ICommunity : string;
  ancestors: P extends 'ancestors' ? ICommunity[] : string[];

  // Location
  center: {
    type: string;
    coordinates: number[];
  };
  polygon: {
    type: string;
    coordinates: number[];
  };

  // Door To Door
  collectionDays?: {
    days: DAYS_OF_WEEK[];
    start?: number; // 0 - 23
    end?: number; // 0 - 23
  };

  language: string;

  balance: number;

  balanceHogar: number;

  balanceFdH: number;

  hasSupply: boolean;

  hasCcBalance: boolean;

  users: number;

  population: number;

  usersPerHome: number;

  activationDate: string | Date;

  phase: {
    realCommunity: string;
    metrics: Array<{
      metric: STATISTIC_TYPE;
      percentage: number;
    }>;
  };

  smartRing?: SMART_RING_TYPE;
  disabledDonations?: boolean;
  disabledIncentives?: {
    lotteries: boolean;
    crowds: boolean;
  };
  disabled: boolean;

  marketplaceActivationDate?: string | Date;
  regionDay?: {
    day: number;
    month: number;
  };
};
