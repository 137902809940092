import {PermissionApiKeysType, ReciclosApplications} from './types';
import {USER_ACTIONS} from './actions';

export const PermissionApiKeys: PermissionApiKeysType = {
  [ReciclosApplications.NoToken]: [USER_ACTIONS.NO_AUTH],
  [ReciclosApplications.MainApp]: [USER_ACTIONS.USE_GUEST_WEBAPP, USER_ACTIONS.USE_AUTHORIZED_WEBAPP],
  [ReciclosApplications.BackofficeApp]: [
    USER_ACTIONS.USE_PUBLIC_BACKOFFICE,
    USER_ACTIONS.VIEW_DASHBOARD_GLA,
    USER_ACTIONS.VIEW_DASHBOARD_KPMG,
    USER_ACTIONS.VIEW_STATISTICS_KPMG,
    USER_ACTIONS.VIEW_DASHBOARD_MKT,
    USER_ACTIONS.SELF_ADMINISTRATE,
    USER_ACTIONS.MONTHLY_PDF_REPORTS,
    USER_ACTIONS.DEVELOPERS_MODE,
    USER_ACTIONS.UPLOAD_FILES,
    USER_ACTIONS.DEMO_SMART_RING,
    USER_ACTIONS.EXECUTE_ACTIONS,
    USER_ACTIONS.REVISE_DUPLICATE_PHOTOS,
    USER_ACTIONS.REVISE_INCORRECT_PHOTOS,
    USER_ACTIONS.CHECK_DOOR_TO_DOOR_PHOTOS,
    USER_ACTIONS.CHECK_MICRO_INCENTIVE_PHOTOS,
    USER_ACTIONS.SUPERUSER_MARKETING,
    USER_ACTIONS.TRACKER_CONTAINERS,
    USER_ACTIONS.USERS_MANAGEMENT,
    USER_ACTIONS.SMARTRING_CRUDL,
    USER_ACTIONS.CHECK_AVANT_PHOTOS,
    USER_ACTIONS.COORDINADOR_MNG,
    USER_ACTIONS.CONTROL_OPERACIONES,
    USER_ACTIONS.CHECK_HUPI_PHOTOS,
    USER_ACTIONS.GESTION_ENVASES,
  ],
  [ReciclosApplications.VivaMarketing]: [USER_ACTIONS.VIVA_MARKETING],
  [ReciclosApplications.CRM]: [USER_ACTIONS.CRM],

  [ReciclosApplications.WEB]: [USER_ACTIONS.USE_EXTERNAL_WEBSERVICE],
  [ReciclosApplications.ContainersApp]: [USER_ACTIONS.USE_GUEST_WEBAPP, USER_ACTIONS.COORDINADOR_MNG, USER_ACTIONS.CONTAINERS_USER_SCAN],
  [ReciclosApplications.AireApp]: [USER_ACTIONS.USE_GUEST_AIRE, USER_ACTIONS.USE_AUTHORIZED_AIRE],
};
