export enum USER_ACTIONS {
  // Old permissions
  USE_GUEST_WEBAPP = 'USE_GUEST_WEBAPP',
  USE_AUTHORIZED_WEBAPP = 'USE_AUTHORIZED_WEBAPP',
  VIVA_MARKETING = 'VIVA_MARKETING',
  // New permissions actions
  NO_AUTH = 'NO_AUTH',
  USE_PUBLIC_BACKOFFICE = 'USE_PUBLIC_BACKOFFICE',
  SELF_ADMINISTRATE = 'SELF_ADMINISTRATE',
  VIEW_DASHBOARD_GLA = 'VIEW_DASHBOARD_GLA',
  VIEW_DASHBOARD_KPMG = 'VIEW_DASHBOARD_KPMG',
  VIEW_STATISTICS_KPMG = 'VIEW_STATISTICS_KPMG',
  VIEW_DASHBOARD_MKT = 'VIEW_DASHBOARD_MKT',
  MONTHLY_PDF_REPORTS = 'MONTHLY_PDF_REPORTS',
  UPLOAD_FILES = 'UPLOAD_FILES',
  EXECUTE_ACTIONS = 'EXECUTE_ACTIONS',
  DEVELOPERS_MODE = 'DEVELOPERS_MODE',
  CRM = 'CRM',
  DEMO_SMART_RING = 'DEMO_SMART_RING',
  REVISE_DUPLICATE_PHOTOS = 'REVISE_DUPLICATE_PHOTOS',
  REVISE_INCORRECT_PHOTOS = 'REVISE_INCORRECT_PHOTOS',
  CHECK_MICRO_INCENTIVE_PHOTOS = 'CHECK_MICRO_INCENTIVE_PHOTOS',
  CHECK_DOOR_TO_DOOR_PHOTOS = 'CHECK_DOOR_TO_DOOR_PHOTOS',
  USE_EXTERNAL_WEBSERVICE = 'USE_EXTERNAL_WEBSERVICE',
  SUPERUSER_MARKETING = 'SUPERUSER_MARKETING',
  TRACKER_CONTAINERS = 'TRACKER_CONTAINERS',
  USERS_MANAGEMENT = 'USERS_MANAGEMENT',
  CONTAINERS_USER_SCAN = 'CONTAINERS_USER_SCAN',
  COORDINADOR_MNG = 'COORDINADOR_MNG',
  SMARTRING_CRUDL = 'SMARTRING_CRUDL',
  CHECK_AVANT_PHOTOS = 'CHECK_AVANT_PHOTOS',
  CONTROL_OPERACIONES = 'CONTROL_OPERACIONES',
  CHECK_HUPI_PHOTOS = 'CHECK_HUPI_PHOTOS',
  USE_GUEST_AIRE = 'USE_GUEST_AIRE',
  USE_AUTHORIZED_AIRE = 'USE_AUTHORIZED_AIRE',
  GESTION_ENVASES = 'GESTION_ENVASES',
}
