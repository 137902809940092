import {USER_ROLE} from '../constants';
import {PermissionRulesType} from './types';
import {USER_ACTIONS} from './actions';

export const PermissionRules: PermissionRulesType = {
  /**
   * No role
   */
  [USER_ROLE.NO]: {
    actions: [USER_ACTIONS.NO_AUTH, USER_ACTIONS.USE_GUEST_WEBAPP, USER_ACTIONS.USE_PUBLIC_BACKOFFICE, USER_ACTIONS.USE_GUEST_AIRE],
  },

  /**
   * Web App user role
   */
  [USER_ROLE.USER]: {
    actions: [USER_ACTIONS.USE_AUTHORIZED_WEBAPP],
  },

  /**
   * Viva Marketing role
   */
  [USER_ROLE.MARKETING]: {
    actions: [USER_ACTIONS.VIVA_MARKETING],
  },

  /**
   * Notary role
   */
  [USER_ROLE.NOTARY]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.VIEW_DASHBOARD_GLA],
  },

  /**
   * Admin role
   */
  [USER_ROLE.ADMIN]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.VIEW_DASHBOARD_GLA],
  },

  /**
   * Attention role
   */
  [USER_ROLE.ATTENTION]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.VIEW_DASHBOARD_GLA],
  },

  /**
   * GLA
   */
  [USER_ROLE.GLA]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.VIEW_DASHBOARD_GLA, USER_ACTIONS.MONTHLY_PDF_REPORTS],
  },

  /**
   * KPMG
   */
  [USER_ROLE.KPMG]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.VIEW_DASHBOARD_KPMG, USER_ACTIONS.VIEW_STATISTICS_KPMG],
  },

  /**
   * KPMG
   */
  [USER_ROLE.MKT]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.VIEW_DASHBOARD_MKT],
  },

  /**
   * CRM role
   */
  [USER_ROLE.CRM]: {
    actions: [USER_ACTIONS.CRM],
  },

  /**
   * DEMO
   */
  [USER_ROLE.DEMO]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.DEMO_SMART_RING],
  },

  /**
   * REVISOR
   */
  [USER_ROLE.REVISOR]: {
    actions: [
      USER_ACTIONS.SELF_ADMINISTRATE,
      USER_ACTIONS.REVISE_INCORRECT_PHOTOS,
      USER_ACTIONS.REVISE_DUPLICATE_PHOTOS,
      USER_ACTIONS.CHECK_DOOR_TO_DOOR_PHOTOS,
      USER_ACTIONS.CHECK_MICRO_INCENTIVE_PHOTOS,
      USER_ACTIONS.CHECK_AVANT_PHOTOS,
      USER_ACTIONS.CHECK_HUPI_PHOTOS,
    ],
  },

  /**
   * DEVELOPERS
   */
  [USER_ROLE.DEVELOPERS]: {
    actions: [
      USER_ACTIONS.SELF_ADMINISTRATE,
      USER_ACTIONS.REVISE_INCORRECT_PHOTOS,
      USER_ACTIONS.REVISE_DUPLICATE_PHOTOS,
      USER_ACTIONS.CHECK_MICRO_INCENTIVE_PHOTOS,
      USER_ACTIONS.CHECK_DOOR_TO_DOOR_PHOTOS,
      USER_ACTIONS.DEVELOPERS_MODE,
      USER_ACTIONS.UPLOAD_FILES,
      USER_ACTIONS.EXECUTE_ACTIONS,
      USER_ACTIONS.CHECK_AVANT_PHOTOS,
      USER_ACTIONS.CHECK_HUPI_PHOTOS,
    ],
  },

  /**
   * MNG_CONTAINERS
   */
  [USER_ROLE.MNG_CONTAINERS]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.TRACKER_CONTAINERS],
  },

  /**
   * MNG_USERS
   */
  [USER_ROLE.MNG_USERS]: {
    actions: [USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.USERS_MANAGEMENT],
  },

  /**
   * USER_COORDINATOR
   */
  [USER_ROLE.USER_COORDINATOR]: {
    actions: [USER_ACTIONS.COORDINADOR_MNG],
  },

  /**
   * USER_SCAN
   */
  [USER_ROLE.USER_SCAN]: {
    actions: [USER_ACTIONS.CONTAINERS_USER_SCAN],
  },

  /**
   * EXTERNAL WEB
   */
  [USER_ROLE.EXTERNAL_WEBSERVICE]: {
    actions: [USER_ACTIONS.USE_EXTERNAL_WEBSERVICE],
  },

  /**
   * SUPERUSER_MARKETING
   */
  [USER_ROLE.SUPERUSER_MARKETING]: {
    actions: [USER_ACTIONS.SUPERUSER_MARKETING, USER_ACTIONS.SELF_ADMINISTRATE],
  },

  /**
   * SMARTRING
   */
  [USER_ROLE.SMARTRING]: {
    actions: [USER_ACTIONS.SMARTRING_CRUDL, USER_ACTIONS.SELF_ADMINISTRATE],
  },

  /**
   * CONTROL_OPERACIONES
   */
  [USER_ROLE.CONTROL_OPERACIONES]: {
    actions: [USER_ACTIONS.CONTROL_OPERACIONES, USER_ACTIONS.SELF_ADMINISTRATE, USER_ACTIONS.GESTION_ENVASES],
  },

  /**
   * Aire App user role
   */
  [USER_ROLE.USER_AIRE]: {
    actions: [USER_ACTIONS.USE_AUTHORIZED_AIRE],
  },
};
